import React from "react"
import PageRoot from "../components/PageRoot"
import { useTranslation } from "react-i18next"
import wallpaper from "../../static/assets/images/bitcoin-wallpaper.png"
import GenericHeader from "../components/GenericHeader"
import CourseDetailPage from "../templates/CourseDetailPage"
import Faq from "../components/Faq"
import CourseVideoList from "../components/CourseVideoList"
import { Col, Progress, Row, Skeleton, Space, Timeline } from "antd"
import ReadOnlyQuill from "../components/ReadOnlyQuill"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import AuthorListDetail from "../components/AuthorListDetail"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { FavoriteButton, PlayButton, PurchaseButton } from "../components/Buttons"

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <div className={"page-not-found"}>
      <PageRoot>
        <GenericHeader
          data={{
            title: "404",
            subtitle: "Page not found",
            wallpaper: wallpaper
          }}
        />
      </PageRoot>
    </div>
  )
}

export default NotFoundPage
